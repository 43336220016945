<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="字典名称">
          <el-input v-model="searchForm.text" autocomplete="off" size="small" placeholder="请输入字典名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="词性">
          <el-input v-model="searchForm.dictkey" autocomplete="off" size="small" placeholder="请输入词性" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="dictList" stripe style="width: 100%" height="100%">
        <el-table-column prop="keyText" label="字典名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="dictkey" label="词性" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.createTime|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="修改时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.updateTime|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看" placement="top">
              <el-button type="primary" @click="open('view',scope.row.dictkey)" size="mini" icon="el-icon-search" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" @click="deleteDict(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="15" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 字典表单 -->
    <el-dialog title="添加字典" :visible.sync="dictShow" width="30%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="dict" ref="dictForm" :rules="rules" label-width="100px">
        <el-form-item label="字典名称" prop="keyText">
          <el-input v-model="dict.keyText" autocomplete="off" placeholder="请输入字典名称"></el-input>
        </el-form-item>
        <el-form-item label="词性" prop="dictkey">
          <el-input v-model="dict.dictkey" autocomplete="off" placeholder="请输入词性"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dictShow = false">取 消</el-button>
        <el-button type="primary" @click="addDict">添 加</el-button>
      </div>
    </el-dialog>
    <!-- 字典内容 -->
    <el-dialog title="字典内容" :visible.sync="contentShow" width="30%" top="2%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <div class="container noangle">
        <div class="header">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item>
              <el-button type="success" size="small" @click="open('add2')" icon="el-icon-plus">新增</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="content">
          <el-table :data="contentList" stripe style="width: 100%" height="500">
            <el-table-column prop="text" label="字典名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                  <el-button type="danger" @click="deleteKey(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <!-- 字典表单 -->
    <el-dialog title="添加字典内容" :visible.sync="keyShow" width="30%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="key" ref="keyForm" :rules="rules2" label-width="100px">
        <el-form-item label="字典文本" prop="text">
          <el-input v-model="key.text" autocomplete="off" placeholder="请输入字典文本"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="keyShow = false">取 消</el-button>
        <el-button type="primary" @click="addKey">添 加</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dictList: [],
      contentList: [],
      currentPage: 1,
      total: 0,
      searchForm: {},
      dict: {},
      key: {},
      dictkey: '',
      dictShow: false,
      contentShow: false,
      keyShow: false,
      rules: {
        keyText: [
          { required: true, message: '请输入字典名称', trigger: 'blur' }
        ],
        dictkey: [
          { required: true, message: '请输入词性', trigger: 'blur' }
        ]
      },
      rules2: {
        keyText: [
          { required: true, message: '请输入字典名称', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initTable()
  },
  methods: {
    // 初始化表格
    async initTable () {
      const data = {}
      if (this.searchForm.text) {
        data.text = this.searchForm.text
      }
      if (this.searchForm.dictkey) {
        data.dictkey = this.searchForm.dictkey
      }
      this.searchForm.pageNo = this.currentPage
      this.searchForm.pageSize = 15
      const { data: result } = await this.$axios.get('/dict/listType', { params: this.searchForm })
      if (result.code === 200) {
        this.dictList = result.data.result
        this.total = result.data.total
      }
    },
    // 初始化字典内容列表
    async initList () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: this.dictkey } })
      if (result.code === 200) {
        this.contentList = result.data
      }
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.dictShow = true
          this.$nextTick(() => {
            this.$refs.dictForm.clearValidate()
          })
          break
        case 'view':
          this.dictkey = row
          this.initList()
          this.contentShow = true
          break
        case 'add2':
          this.keyShow = true
          this.$nextTick(() => {
            this.$refs.keyForm.clearValidate()
          })
          break
      }
    },
    // 添加
    async addDict () {
      this.$refs.dictForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/dict/addkey', this.dict)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.dialog = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async addKey () {
      this.$refs.keyForm.validate(async valid => {
        if (valid) {
          const data = {
            ...this.key,
            dictkey: this.dictkey
          }
          const { data: result } = await this.$axios.post('/dict/add', data)
          if (result.code === 200) {
            this.$message.success('新增成功')
            this.keyShow = false
            this.initList()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除某条数据
    async deleteDict (id) {
      const confirm = await this.$confirm('此操作将永久删除该字典, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/dict/delkey/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    async deleteKey (id) {
      const confirm = await this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/dict/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          this.initList()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    clear () {
      this.dict = {}
      this.key = {}
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
